.admin-dashboard {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-dashboard h2 {
  text-align: center;
  color: #333;
}

.admin-dashboard h3 {
  margin-top: 20px;
  color: #4CAF50;
}

.spiel-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.spiel-form input[type="text"],
.spiel-form input[type="date"],
.spiel-form input[type="number"] {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.spiel-form button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.spiel-form button:hover {
  background-color: #45a049;
}

.kegler-ergebnis {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.kegler-ergebnis h4 {
  margin-bottom: 10px;
  color: #333;
}

.kegler-ergebnis label {
  display: inline-block;
  width: 100px;
  font-weight: normal;
  color: #555;
}

.kegler-ergebnis input {
  width: 50px;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #4CAF50;
  border-radius: 0px;
  font-size: 16px;
}

.kegler-ergebnis button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.kegler-ergebnis button:hover {
  background-color: #45a049;
}

select {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}
