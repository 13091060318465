.jahr-auswahl select {
  width: auto;
  min-width: 80px; /* Setze eine minimale Breite */
}

.kegel-tabelle {
  padding: 20px;
}

.kegel-tabelle h2 {
  text-align: center;
  margin-bottom: 20px;
}

.kegel-tabelle h3 {
  margin-top: 20px;
  text-align: center;
}

.kegel-tabelle table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.kegel-tabelle th, .kegel-tabelle td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.kegel-tabelle th {
  background-color: #f2f2f2;
}

.kegel-tabelle tr:nth-child(even) {
  background-color: #f9f9f9;
}

.kegel-tabelle tr:hover {
  background-color: #ddd;
}

.kegel-tabelle th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4CAF50;
  color: white;
}

.spezial-tabellen {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.small-table {
  width: 30%;
}

.fixed-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
.export-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.export-button:hover {
  background-color: #45a049;
}

